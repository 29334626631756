@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MavenPro-Black";
  src: url("../public/fonts/MavenPro-Black.ttf");
}

@font-face {
  font-family: "MavenPro-Bold";
  src: url("../public/fonts/MavenPro-Bold.ttf");
}

@font-face {
  font-family: "MavenPro-ExtraBold";
  src: url("../public/fonts/MavenPro-ExtraBold.ttf");
}

@font-face {
  font-family: "MavenPro-Medium";
  src: url("../public/fonts/MavenPro-Medium.ttf");
}

@font-face {
  font-family: "MavenPro-Regular";
  src: url("../public/fonts/MavenPro-Regular.ttf");
}

@font-face {
  font-family: "MavenPro-SemiBold";
  src: url("../public/fonts/MavenPro-SemiBold.ttf");
}

/* Cyberpunk Button Styles with Skewed Line Gradient */
.cyberpunk-button {
  display: inline-block;
  text-transform: uppercase;
  /* border: none; */
  /* border-radius: 5px; */
  border: 4px solid white;
  background: linear-gradient(45deg, #000000, #3e0474); /* Diagonal gradient */
  color: #ffffff; /* White text color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative; /* Needed for pseudo-element */
  opacity: 0.8;
}

.cyberpunk-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #ff00ff 10px,
    #000000 20px
  );
  opacity: 0.3;
  z-index: -1;
}

.cyberpunk-button:hover {
  background: linear-gradient(45deg, #ff00ff, #000000);
  color: #ffffff;
  cursor: pointer;
}

.background {
  filter: brightness(112%) contrast(150%) invert(1) sepia(100);
  background-image: url("../public/media/marble.jpg");
  position: fixed;
  top: 0;
  height: 200vh;
  width: 100%;
  z-index: -1;
}

.cboth {
  filter: brightness(50%) contrast(90%);
}

.insta {
  filter: invert(1) saturate(0) brightness(250%);
}

.aurora {
  filter: contrast(100%) brightness(100%);
}

.fade-in {
  opacity: 1;
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

.fade-in-slower {
  opacity: 1;
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

.fade-in-slowest {
  opacity: 1;
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

.brighter {
  filter: brightness(130%) contrast(90%);
}

.player-wrapper {
  position: relative;
  /* padding-top: 56.25%; */
  /* 720 / 1280 = 0.5625 */
  height: 100%;
  width: 60vw;
  min-height: 500px;
  max-width: 80%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  /* filter: sepia(50%) hue-rotate(170deg) brightness(90%); */
  filter: brightness(110%);
}

.blu {
  color: blue;
  font-weight: bold;
}

.afterbuymessage {
  color: white;
  align-items: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}